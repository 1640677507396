@import './../../../styles/config.scss';

.suggestionsBox {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow-y: auto;
    height: 300px;
    
    .suggestionItem {
        padding: 10px;

        &:hover {
            background-color: #ddd;
            cursor: pointer;
        }
    }
}

.searchModal {
    z-index: 1000;
    height: calc(100vh - 40px);
    overflow-y: auto;
    padding: 140px 20px 60px;
}