@import './../../../styles/color.scss';

.container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.bgPrimary {
  background-color: #ef4727;
}

.leftColumn, .rightColumn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftColumn {
  background-color: #ef4727; // Optional: Add a background color
  width: 40%;
}

.rightColumn {
  background: url('../../../assets/logo//bg_.webp') no-repeat center center;
  background-size: cover;
}

.card {
  width: 400px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .card {
    width: 250px;
    
  }

  .leftColumn {
    flex: none;
    width: 100%;
    order: 1;
    height: 100vh;
  }

  .rightColumn {
    display: none;
  }
}
