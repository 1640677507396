  .container {
    padding-bottom: 100px;
    // background-color: #ffbfb4;
  }

  .icon {
    color: #fff;
  }
  .bigView {
    // padding: 30px;
    padding-left: 20px;
    // margin-top: 25px;
    // margin-bottom: 25px;
    font-size: 30px;
    background-color: #fff;
    display: inline-block;
    padding: 25px;
    margin-bottom: 25px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 10px solid #ef4727;
  }
  .gridContainer {
    padding: 20px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }
  
  .gridItem {
    cursor: pointer;
    width: auto;
  }

  @media (max-width: 768px) {
    .gridItem {
        cursor: pointer;
        width: auto;
    }
  }