.container {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.bgPrimer {
  background-color: #ef4727!important;
}