@import './../../styles/config.scss';

.container {
  background-color: #ef4727;
  z-index: 100;

  .navbar {
    user-select: none;
    width: $screenWidth;
    padding: 0px 20px;
    height: $navbarHeight;
    
    .barsIcon {
      cursor: pointer;
      color: #fff;
    }
  }

  .items {
    padding: 0 20px;
    height: calc(100vh - $navbarHeight);
    overflow:scroll;

  }
  
}