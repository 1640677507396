@import './../../styles/config.scss';

.container {
  background-color: #EDEBE9;
}

.navbar {
  transition: top 0.3s;
  position: fixed;
}

.content {
  padding-top: $navbarHeight;
  min-height: calc(100vh);
  // margin-left: 300px;
  background-color: #f6f7fc
}

.navbar.hidden {
  top: -$navbarHeight;
}