@import '../styles/config.scss';

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;

  .content {
    height: 100vh;
    width: $screenWidth;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: $screenWidth) {
  .content {
    width: 100vw;
  }
}