@import './../../../styles/config.scss';

.backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.input {
    background-color: #f4f4f5;
    border: 1px solid #e5e7eb;
    width: 100%;
    border-radius: 12px;
}

.inputContainer {
    position: relative;
}

.suggestionsBox {
    position: absolute;
    top: 50px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: $box-shadow;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 300px;
    min-width: 300px;
    z-index: 999;

    .suggestionItem {
        padding: 10px;

        &:hover {
            background-color: #ddd;
            cursor: pointer;
        }
    }
}

.selectedItem {
    padding: 5px;

    .content {

        .body {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background-color: #e5e7eb;
            border: 1px solid #ccc;
            border-right-width: 0px;
            padding: 5px 12px;
        }

        .delete {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            background-color: #e5e7eb;
            border: 1px solid #ccc;
            border-left-width: 0px;
            padding: 3px 8px;

            &:hover {
                cursor: pointer;
                background-color: #ccc;
            }
        }
    }
}