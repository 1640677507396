.container {
  .title {
    background-color: #C8C6C4;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .item {
    padding: 10px 20px;
  }
}